<template>
  <!-- 菜单权限管理 -->
  <div class="paddbox autobox">
    <div>
      <el-card>
        <div class="toutiaoAddModelTitleTop flex">
          <div>
            <el-button
              type="primary"
              :disabled="openSonviewAble"
              @click="upAccountFun()"
              >{{
                openSonviewAble == false ? '请选择子账户' : '已选择完成'
              }}</el-button
            >
          </div>
          <div>
            <div class="title">
              <el-tooltip
                class="item"
                effect="dark"
                :content="sonString"
                placement="right"
              >
                <div>已选:({{ accountIds.length }})</div>
              </el-tooltip>
            </div>
          </div>
          <!-- <div>
            <selectSonAccount
              :data="selectSonAccountData"
            ></selectSonAccount>
          </div> -->
          <div>
            <el-button type="danger" round @click="ResetPageFun()"
              >重置</el-button
            >
          </div>
        </div></el-card
      >
    </div>
    <div>
      <el-card>
        <div>
          <div>
            <el-form label-position="right" label-width="160px">
              <!-- <el-form-item label="页面转化类型">
                <el-radio-group v-model="selfAttributed">
                  <el-radio :label="false">首冲数据接入</el-radio>
                  <el-radio :label="true">已有数据上报</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="数据源">
                <el-table :data="sjy" border style="width: 100%">
                  <el-table-column prop="accountId" label="账户id" width="180">
                  </el-table-column>
                  <el-table-column label="数据源">
                    <template #default="scope">
                      <el-select
                        @click="selectDataBaseFun(scope)"
                        filterable
                        v-model="scope.row.sjyValue"
                        clearable
                        placeholder="请选择数据源"
                      >
                        <el-option
                          v-for="item in sjy[scope.$index].sjyData"
                          :key="item.value"
                          :label="item.userActionSetId+'('+item.name+')'"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>                      
                    </template>
                  </el-table-column>
                </el-table>
              </el-form-item> -->
              <el-form-item label="转化名称">
                <el-input
                  v-model="conversionName"
                  style="width:600px"
                  placeholder="请输入转化名称"
                ></el-input>
              </el-form-item>
              <el-form-item label="转化类型">
                <el-radio-group v-model="conversionScene">
                  <el-radio :label="'CONVERSION_SCENE_WECHAT_MINI_PROGRAM'"
                    >微信小程序</el-radio
                  >
                </el-radio-group>
              </el-form-item>
              <el-form-item
                label="小程序ID"
                v-if="conversionScene == 'CONVERSION_SCENE_WECHAT_MINI_PROGRAM'"
              >
                <el-input
                  style="width:200px"
                  v-model="miniProgramId"
                  placeholder="请输入小程序ID"
                  clearable
                ></el-input>
              </el-form-item>
              <el-form-item label="优化目标">
                <el-select
                  @click="selectOptimizationGoalFun()"
                  filterable
                  v-model="optimizationGoal"
                  clearable
                  placeholder="请选择优化目标"
                >
                  <el-option
                    v-for="item in optimizationGoalList"
                    :key="item.value"
                    :label="item.lable"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="深度优化">
                <el-switch v-model="sdyh" active-text="开启" inactive-text="">
                </el-switch>
              </el-form-item>
              <!-- <el-form-item label="深度优化类型">
                <el-radio-group v-model="claimType">
                  <el-radio :label="'CLAIM_TYPE_CLICK'">优化转化行为</el-radio>
                  <el-radio :label="'CLAIM_TYPE_CLICK'">优化ROI</el-radio>
                </el-radio-group>
              </el-form-item> -->
              <el-form-item label="深度优化目标" v-if="sdyh == true">
                <el-select
                  filterable
                  v-model="deepBehaviorOptimizationGoal"
                  clearable
                  placeholder="请选择优化目标"
                >
                  <el-option
                    v-for="item in optimizationGoalList"
                    :key="item.value"
                    :label="item.lable"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="营销链路">
                <el-radio-group v-model="conversionType">
                  <el-radio :label="0">关闭</el-radio>
                  <el-radio :label="1">开启</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="归因方式">
                <el-radio-group v-model="claimType">
                  <el-radio :label="'CLAIM_TYPE_CLICK'">点击归因</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="点击监测链接">
                <el-table :data="urls" border style="width: 100%">
                  <el-table-column prop="accountId" label="账户id" width="180">
                  </el-table-column>
                  <el-table-column label="监测链接">
                    <template #default="scope">
                      <el-input
                        v-model="scope.row.impressionFeedbackUrl"
                        placeholder="请输入监测链接"
                        clearable
                      ></el-input>
                    </template>
                  </el-table-column>
                </el-table>
              </el-form-item>
              <el-form-item>
                <el-button @click="createConversionsFun()" type="primary"
                  >提交</el-button
                >
              </el-form-item>
            </el-form>
          </div>
          <div class="downBtn flex">
            <div>
              <!-- <el-button @click="openConversionFun()">取消</el-button> -->
            </div>
            <div></div>
          </div></div
      ></el-card>
    </div>

    <div>
      <!-- 选择子账户弹窗 -->
      <el-dialog v-model="openSonview">
        <insertViewMP
          @getAccountListFun="getSonAccountListFun"
          @getSelectSonAccountData="getSelectSonAccountData"
          @getCannelFun="getCannelFun"
        ></insertViewMP>
      </el-dialog>

      <!-- 批量添加转化归因弹窗 -->
      <el-dialog
        v-model="openConversionView"
        title="新建网页转化 - API(自归因上报)"
      >
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { selectClipLibraryAll } from '@/request/new/videosucai'
import {
  userActionSetsget,
  createConversions,
  selectOPTIMIZATIONGOAL,
} from '@/request/new/batchManagement'
import insertViewMP from '@/components/batch/insertViewMP.vue'
import { ElMessage } from 'element-plus'
import { inject, reactive, toRefs, watch } from 'vue'

export default {
  name: 'MPconversion',
  components: { insertViewMP },
  setup() {
    const state = reactive({
      //上报方式，默认api 前端不用给我传
      accessType: '',
      //版本类型  V2.0  V3.0
      version: 'V3.0',
      // //网页转化类型
      // selfAttributed:true,
      //转化名称
      conversionName: '',
      //转化类型  CONVERSION_SCENE_WECHAT_MINI_PROGRAM微信小程序
      conversionScene: 'CONVERSION_SCENE_WECHAT_MINI_PROGRAM',
      //小程序 id。微信小程序转化场景
      miniProgramId: '',
      //优化目标
      optimizationGoal: '',
      //优化目标数组
      optimizationGoalList: [],
      //深度优化目标
      deepBehaviorOptimizationGoal: '',
      //营销开关 0关闭 1打开
      conversionType: 0,
      //归因方式 点击归因 CLAIM_TYPE_CLICK
      claimType: 'CLAIM_TYPE_CLICK',
      //默认自归因 前端不用管
      selfAttributed: false,
      //监测链接
      urls: [],
      //数据源
      sjy:[],
      //深度优化目标滑块
      sdyh: false,
      // 打开批量新建转化归因的弹窗
      openConversionView: false,
      // 打开选择子账户的弹窗
      openSonview: false,
      // 选择子账户的按钮是否可用
      openSonviewAble: false,
      // 选中的子账户数据
      accountIds: [],
      // 子账户的字符串
      sonString: '',
      // 重置
      ResetPageFun: () => {
        location.reload()
      },
      // 选择子账户的按钮值的变化
      openSonviewAbleFun: () => {
        state.openSonviewAble = !state.openSonviewAble
      },
      // 批量添加转化归因值的变化
      openConversionFun: () => {
        if (state.accountIds.length == 0) {
          return ElMessage({
            type: 'error',
            message: '请先选择子账户',
          })
        }
        state.openConversionView = !state.openConversionView
      },
      // 接收组件的取消
      getCannelFun: () => {
        state.upAccountFun()
      },
      // 修改打开选择子账户的弹窗
      upAccountFun: () => {
        state.openSonview = !state.openSonview
      },
      //查询优化目标
      selectOptimizationGoalFun: () => {
        selectOPTIMIZATIONGOAL().then((res) => {
          state.optimizationGoalList = res.data
        })
      },
      //循环拿值
      forVuale: () => {
        state.accountIds.forEach((e) => {
          state.urls.push({ accountId: e, impressionFeedbackUrl: '' })
          state.sjy.push({ accountId: e, sjyValue: '' ,sjyData:''})
        })
      },
      // 接收组件的确定
      getSonAccountListFun: (data) => {
        state.accountIds = data
        state.openSonviewAbleFun()
        data.forEach((item, index) => {
          if (data.length - 1 == index) {
            state.sonString += item
          } else {
            state.sonString += item + '，'
          }
        })
        state.upAccountFun()
        state.forVuale()
      },
      //查询数据源
      selectDataBaseFun: (scope) => {
        const datas = {
          accountId: scope.row.accountId,
        }
        userActionSetsget(datas).then((res) => {
          state.sjy[scope.$index].sjyData=res.data
        })
      },
      //确定
      createConversionsFun: () => {
        //效验子账户
        if (state.accountIds.length == 0) {
          return ElMessage({
            type: 'error',
            message: '请先选择子账户',
          })
        }
        //效验转化名称
        if (state.conversionName == '') {
          return ElMessage({
            type: 'error',
            message: '转化名称不能为空',
          })
        }
        //效验小程序ID
        if (state.miniProgramId == '') {
          return ElMessage({
            type: 'error',
            message: '小程序ID不能为空',
          })
        }
        //效验优化目标
        if (state.optimizationGoal == '') {
          return ElMessage({
            type: 'error',
            message: '优化目标不能为空',
          })
        }
        //效验深度优化目标
        if (state.deepBehaviorOptimizationGoal == '' && state.sdyh == true) {
          return ElMessage({
            type: 'error',
            message: '深度优化目标不能为空',
          })
        }
        //效验点击监测链接
        state.urls.forEach((e) => {
          if (e.impressionFeedbackUrl == '') {
            return ElMessage({
              type: 'error',
              message: '账户' + e.accountId + '的检测链接不能为空',
            })
          }
        })
        //效验数据源
        //判断
        // state.sjy.forEach((e) => {
        //   if (e.sjyValue == '') {
        //     return ElMessage({
        //       type: 'error',
        //       message: '账户' + e.accountId + '的数据源不能为空',
        //     })
        //   }
        // })




        const datas = {
          version: state.version,
          aids: state.accountIds,
          conversionName: state.conversionName,
          conversionScene: state.conversionScene,
          miniProgramId: state.miniProgramId,
          optimizationGoal: state.optimizationGoal,
          claimType: state.claimType,
          conversionType: state.conversionType,
          urls: state.urls,
        }
        if (state.sdyh == true) {
          datas.deepBehaviorOptimizationGoal =
            state.deepBehaviorOptimizationGoal
        }
        createConversions(datas).then((res) => {
          //清空数据
          // state.accountIds=[]
          // state.conversionName=''
          // state.conversionScene='CONVERSION_SCENE_WECHAT_MINI_PROGRAM'
          // state.miniProgramId=''
          // state.optimizationGoal=''
          // state.conversionType=0
          // state.deepBehaviorOptimizationGoal=''
          // state.urls=[]
          // state.accountIds=[]
          // state.openSonviewAbleFun()
          state.ResetPageFun()
          ElMessage({
            type: 'success',
            message: '创建成功',
          })
        })
      },
    })

    return {
      // 结构赋值，暴露出去就不用 state.xx
      ...toRefs(state),
    }
  },
}
</script>

<style lang="scss" scoped>
.toutiaoAddModelTitleTop {
  > div {
    margin-right: 10px;
  }
}
</style>
